<template xmlns="http://www.w3.org/1999/html">
  <div class="referral-tier">
    <v-snackbar class="pa-0" style="z-index:1002 !important;top:25%" light timeout="-1" v-model="alertShow" width="fit-content" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="mdi-close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
    <v-dialog class="confirmation_card_dialog" persistent :value="claimNowConfirmation" style="margin:10px;" :max-width="440">
      <v-form ref="redeemProduct_form">
        <div class="confirmation_card">
          <v-row class="confirmation_card-title" no-gutters :class="$vuetify.breakpoint.mdAndDown ? ' py-4 px-4' : 'py-5 px-5'">
            <v-col cols="11">
              <label class="dialog-title white--text ma-0 text-capitalize">{{ $t(`label.redeemTitle`) }}</label>
            </v-col>
            <v-col cols="1">
              <v-icon class="dialog-close-icon" @click="closeclaimNowConfirmation">mdi-close</v-icon>
            </v-col>
          </v-row>
          <div class="confirmation_card_wrapper">
            <v-row no-gutters class="ma-0 px-3">
              <v-col cols="12" class="d-flex justify-center align-center">
                <v-img :src="selectedProductImage" alt="Selected Product Image" class="heightIMG">
                  <v-img v-if="selectedProductIsHot === 1" src="/static/svg/game-icon-hot.svg" width="25" height="25" class="hot-icon"></v-img>
                </v-img>
              </v-col>
              <v-col cols="12" class="d-flex justify-start align-center">
                <span class="redeemItemText">{{ selectedProductName }}</span>
              </v-col>
            </v-row>
            <div v-if="quickClaim === 1">
              <v-row no-gutters>
                <v-col cols="12" class="px-3">
                  <label class="redeemItemText ma-0 pb-1 text-capitalize d-block">
                    {{ $t(`label.chooseYourVariations`) }}
                    <span class="red--text ml-1">*</span>
                  </label>
                </v-col>
              </v-row>
              <v-row no-gutters class="ma-0 px-3">
                <v-col v-if="gotVariations && gotVariations.length > 0" cols="12" md="4" class="d-flex justify-start" style="gap: 10px;">
                  <v-btn
                    v-for="option in gotVariations"
                    :key="option.id"
                    :class="{ 'selected-option': selectedOption === option, 'please-select-option': errorMsg !== '' && selectedVariation === null }"
                    :disabled="option.quantity <= 0"
                    class="selectOptions"
                    @click="selectOption(option)"
                  >
                    {{ option.variation_objects }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters class="ma-0 mt-3 px-3">
                <v-col md="12">
                  <label class="redeemItemText ma-0 pb-1 text-capitalize d-block">
                    {{ $t('field.phoneNumber') }}
                    <span class="red--text ml-1">*</span>
                  </label>
                  <div class="d-flex align-items-center">
                    <v-col cols="5" class="pa-0">
                      <v-select
                          v-model.trim="redeemProductObj.dialCode"
                          class="redeemInfo_field_pn phoneNumberPrefix mr-2 black--text"
                          item-text="text"
                          item-value="value"
                          :items="[redeemProductObj.dialCode]"
                          dense
                          outlined
                      >
                        <template #prepend-inner>
                          <v-btn icon small>
                            <v-avatar size="20" class="language-button">
                              <img
                                  width="20"
                                  height="20"
                                  :src="`/static/image/country/${currentCurrency}.svg`"
                                  alt="currency icon"
                              />
                            </v-avatar>
                          </v-btn>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="7" class="pa-0">
                      <v-combobox
                          v-model="phoneNumberList.selectedItem"
                          :items="phoneNumberList.items"
                          class="pt-0 redeemInfo_field_pn"
                          item-value="value"
                          :clearable="true"
                          item-text="value"
                          allow-overflow
                          :no-data-text="$t('label.pleaseFillPhone')"
                          return-object
                          filled
                          solo
                          dense
                          outlined
                      >
                      </v-combobox>
                    </v-col>
                  </div>
                </v-col>
              </v-row>

              <v-row no-gutters v-if="this.errorMsg !== '' && this.selectedVariation === null">
                <v-row no-gutters>
                  <v-col cols="12" class="d-flex">
                    <span class="px-3 redeemItemText red--text">{{ this.errorMsg }}</span>
                  </v-col>
                </v-row>
              </v-row>
              <v-row no-gutters class="ma-0 px-3">
                <v-row no-gutters>
                  <v-col cols="12" class="d-flex justify-center align-center">
                    <span class="redeemItemText">{{ $t(`label.coinsRequired`) }}</span>
                    <span class="d-flex align-center justify-center">
                      <span class="px-1 rewardBalance">{{ coinRequired }}</span>
                    </span>
                  </v-col>
                </v-row>
              </v-row>
              <v-row no-gutters class="">
                <v-col cols="12" class="d-flex justify-center align-center">
                  <v-btn width="80%" @click="redeemNows" class="redeem-Now mb-2">
                    {{ $t(`label.redeemNow`) }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <div v-if="step === 1">
                <v-form ref="redeemProduct_form_1">
                  <v-row no-gutters>
                    <v-col cols="12" class="px-3">
                      <label class="redeemItemText ma-0 pb-1 text-capitalize d-block">
                        {{ $t(`label.chooseYourVariations`) }}
                        <span class="red--text ml-1">*</span>
                      </label>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 px-3">
                    <v-col v-if="gotVariations.length > 0" cols="12" md="4" class="d-flex justify-start" style="gap: 10px;">
                      <v-btn
                        v-for="option in gotVariations"
                        :key="option.id"
                        :class="{ 'selected-option': selectedOption === option, 'please-select-option': errorMsg !== '' && selectedVariation === null }"
                        :disabled="option.quantity <= 0"
                        class="selectOptions"
                        @click="selectOption(option)"
                      >
                        {{ option.variation_objects }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="this.errorMsg !== '' && this.selectedVariation === null">
                    <v-row no-gutters>
                      <v-col cols="12" class="d-flex">
                        <span class="px-3 redeemItemText red--text">{{ this.errorMsg }}</span>
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" class="px-3">
                      <label class="redeemItemText ma-0 pb-1 text-capitalize d-block">
                        {{ $t(`field.shipTo`) }}
                        <span class="red--text ml-1">*</span>
                      </label>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="px-3">
                    <v-col cols="12">
                      <v-text-field
                        class="redeemInfo_field pa-0 ma-0"
                        :enabledLabel="true"
                        v-model="redeemProductObj.name"
                        :isOptional="true"
                        :rules="validator.realnameRules(2, 45)"
                        :placeholder="$t(`fieldHint.fillInFullName`)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 mt-3 px-3">
                    <v-col md="12">
                      <label class="redeemItemText ma-0 pb-1 text-capitalize d-block">
                        {{ $t('field.phoneNumber') }}
                        <span class="red--text ml-1">*</span>
                      </label>
                      <div class="d-flex align-items-center">
                        <v-col cols="5" class="pa-0">
                          <v-select
                              v-model.trim="redeemProductObj.dialCode"
                              class="redeemInfo_field_pn phoneNumberPrefix mr-2 black--text"
                              item-text="text"
                              item-value="value"
                              :items="[redeemProductObj.dialCode]"
                              dense
                              outlined
                          >
                            <template #prepend-inner>
                              <v-btn icon small>
                                <v-avatar size="20" class="language-button">
                                  <img
                                      width="20"
                                      height="20"
                                      :src="`/static/image/country/${currentCurrency}.svg`"
                                      alt="currency icon"
                                  />
                                </v-avatar>
                              </v-btn>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="7" class="pa-0">
                          <v-combobox
                              v-model="phoneNumberList.selectedItem"
                              :items="phoneNumberList.items"
                              class="pt-0 redeemInfo_field_pn"
                              item-value="value"
                              :clearable="true"
                              item-text="value"
                              allow-overflow
                              :no-data-text="$t('label.pleaseFillPhone')"
                              return-object
                              filled
                              solo
                              dense
                              outlined
                          >
                          </v-combobox>
                        </v-col>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" class="px-3">
                      <label class="redeemItemText ma-0 pb-1 text-capitalize d-block">
                        {{ $t(`field.email`) }}
                        <span class="red--text ml-1">*</span>
                      </label>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="px-3">
                    <v-col cols="12">
                      <v-text-field
                        class="redeemInfo_field pa-0 ma-0"
                        :enabledLabel="true"
                        v-model.trim="redeemProductObj.email"
                        :isOptional="true"
                        :placeholder="$t(`fieldHint.fillUpHere`)"
                        :rules="validator.emailRules()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" class="px-3">
                      <label class="redeemItemText ma-0 pb-1 text-capitalize d-block">
                        {{ $t(`field.city`) }}
                        <span class="red--text ml-1">*</span>
                      </label>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="px-3">
                    <v-col cols="12">
                      <v-text-field
                        class="redeemInfo_field pa-0 ma-0"
                        :enabledLabel="true"
                        v-model="redeemProductObj.city"
                        :isOptional="false"
                        :placeholder="$t(`fieldHint.fillInCity`)"
                        :rules="validator.cityRules(5, 30)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
              <div v-if="step === 2">
                <v-row no-gutters>
                  <v-col cols="12" class="px-3" v-if="step === 2">
                    <label class="redeemItemText ma-0 pb-1 text-capitalize d-block">
                      {{ $t(`field.postcode`) }}
                      <span class="red--text ml-1">*</span>
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters class="px-3">
                  <v-col cols="12">
                    <v-text-field
                      class="redeemInfo_field pa-0 ma-0"
                      :enabledLabel="true"
                      v-model.trim="redeemProductObj.postcode"
                      :isOptional="false"
                      :placeholder="$t(`fieldHint.fillInPostcode`)"
                      :rules="validator.postCodeRules(4, 9)"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" class="px-3">
                    <label class="redeemItemText ma-0 pb-1 text-capitalize d-block">
                      {{ $t(`field.address`) }}
                      <span class="red--text ml-1">*</span>
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters class="px-3">
                  <v-col cols="12">
                    <v-text-field
                      class="redeemInfo_field pa-0 ma-0"
                      :enabledLabel="true"
                      v-model="redeemProductObj.address1"
                      :isOptional="false"
                      :placeholder="$t(`fieldHint.fillInAddress`)"
                      :rules="validator.addressRules(5, 50)"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" class="px-3">
                    <label class="redeemItemText ma-0 pb-1 text-capitalize d-block">
                      {{ $t(`field.address2`) }}
                      <span class="red--text ml-1">*</span>
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters class="px-3">
                  <v-col cols="12">
                    <v-text-field
                      class="redeemInfo_field pa-0 ma-0"
                      :enabledLabel="true"
                      v-model="redeemProductObj.address2"
                      :isOptional="false"
                      :placeholder="$t(`fieldHint.fillInAddress2`)"
                      :rules="validator.addressRules(5, 50)"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="ma-0 px-3">
                  <v-row no-gutters>
                    <v-col cols="12" class="d-flex justify-center align-center">
                      <span class="redeemItemText">{{ $t(`label.coinsRequired`) }}</span>
                      <span class="d-flex align-center justify-center">
                        <span class="px-1 rewardBalance">{{ coinRequired }}</span>
                      </span>
                    </v-col>
                  </v-row>
                </v-row>
              </div>
              <v-row no-gutters class="px-3" v-if="step === 2">
                <v-row no-gutters class="">
                  <v-col cols="6" class="d-flex justify-center align-center">
                    <v-btn width="80%" @click="previousPageButton" class="redeem-Now mb-2">
                      {{ $t(`label.back`) }}
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="d-flex justify-center align-center">
                    <v-btn width="80%" @click="redeemNows" class="redeem-Now mb-2">
                      {{ $t(`label.redeemNow`) }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-row>
              <v-row no-gutters class="px-3" v-else>
                <v-row no-gutters class="d-flex justify-center align-center">
                  <v-btn width="100%" @click="nextPageButton" class="redeem-Now mb-2">
                    {{ $t(`label.next`) }}
                  </v-btn>
                </v-row>
              </v-row>
            </div>
          </div>
        </div>
      </v-form>
    </v-dialog>
    <v-dialog class="submitted_card_dialog" persistent :value="requestSubmittedDialog" style="margin:10px;" :max-width="440">
      <div class="submitted_card">
        <v-row class="submitted_card_title" no-gutters :class="$vuetify.breakpoint.mdAndDown ? ' py-5 px-5' : 'py-5 px-5'">
          <v-col cols="11">
            <label class="dialog-title white--text ma-0 text-capitalize d-block full-width">{{ $t(`label.reqSubmitted`) }}</label>
          </v-col>
          <v-col cols="1">
            <v-icon class="dialog-close-icon white--text" @click="closerequestSubmittedDialog">mdi-close</v-icon>
          </v-col>
        </v-row>
        <!--        <v-row no-gutters>-->
        <!--          <v-col cols="12" class="d-flex justify-center align-center">-->
        <!--            <v-img :src="'/static/image/rewardStore/popUp-rewardSent.png'" class="reqConfirmed_image"></v-img>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-center align-center subtitle">
            {{ $t(`label.reqSubmittedSubtitle`) }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-center align-center text-center my-3 des">
            {{ $t(`label.reqSubmittedDes1`) }}{{ getCurrentDateTime(this.currentCurrency) }}. {{ $t(`label.reqSubmittedDes2`) }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-center align-center">
            <v-btn class="item-button-close d-flex align-center justify-center mb-2" @click="closerequestSubmittedDialog">{{ $t('label.close') }}</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
    <v-dialog class="submitted_card_dialog" persistent :value="showNotEnoughBalanceDialog" style="margin:10px;" :max-width="440">
      <div class="submitted_card">
        <v-row class="submitted_card_title" no-gutters :class="$vuetify.breakpoint.mdAndDown ? ' py-5 px-5' : 'py-5 px-5'">
          <v-col cols="11">
            <label class="dialog-title white--text ma-0 text-capitalize d-block full-width">{{ $t(`label.notEnufBalance`) }}</label>
          </v-col>
          <v-col cols="1">
            <v-icon class="dialog-close-icon" @click="closeNotEnoughBalanceDialog">mdi-close</v-icon>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-center align-center text-center my-3 des">
            {{ $t(`label.notEnufBalanceDes`) }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-center align-center">
            <v-btn class="item-button-close mb-2 d-flex align-center justify-center" @click="closeNotEnoughBalanceDialog">{{ $t('label.close') }}</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
    <!--    desktop view-->
    <div class="hidden-sm-and-down">
      <div v-if="$vuetify.breakpoint.mdAndUp">
        <v-row no-gutters class="d-flex align-center">
          <v-col cols="7" class="d-flex align-center pa-0">
            <span class="pr-3">{{ $t(`label.sortBys`) }} :</span>
            <v-select v-model="selectedCategory" :items="categories" item-text="label" item-value="value" dense @change="selectCategory" class="custom-select"></v-select>
          </v-col>
        </v-row>
        <v-container class="Product-Container py-5">
          <v-row style="max-width: 100% !important;gap: 10px 0px;">
            <v-col v-for="product in reward" :key="product.id" style="width: 20%; max-width: 20%; padding: 5px 8px">
              <v-card :class="{ 'inactive-product': product.is_active !== 1 }" class="product-container">
                <v-img :src="product.image_cdn" height="180" contain>
                  <v-img v-if="product.is_hot === 1" src="/static/svg/game-icon-hot.svg" width="25" height="25" class="hot-icon"></v-img>
                </v-img>
                <v-card-title>
                  <span class="px-1 points_required">{{ formatPoints(product.points_required) }}</span>
                </v-card-title>
                <v-card-subtitle class="subtitle_class">
                  <span class="productName">{{ truncatedRewardItemName(product.reward_item_name) }}</span>
                </v-card-subtitle>

                <v-card-actions class="justify-center action_class">
                  <v-btn v-if="product.is_active === 1 && product.total_quantity > 0" color="var(--v-newMobilePrimary-base)" class="claimNow" @click="handleClaimClick(product)">
                    {{ $t(`label.claimNow`) }}
                  </v-btn>
                  <v-btn class="claimNow" v-else-if="product.is_active === 1 && product.total_quantity <= 0" style="text-transform: none;" disabled>
                    {{ $t(`label.outOfStock`) }}
                  </v-btn>
                  <v-btn class="claimNow" v-else style="text-transform: none;" disabled>{{ $t(`label.comingSoon`) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-else>
        <div class="detailContainer_mobile">
          <v-container class="Product-Container">
            <v-row style="max-width: 100%; background-color: #EFF0F5; margin: 0px;">
              <v-col v-for="product in reward" style="padding: 6px;" :key="product.id" cols="6">
                <v-card :class="{ 'inactive-product': product.is_active !== 1 }" class="product-container">
                  <v-img :src="product.image_cdn" height="115" contain>
                    <v-img v-if="product.is_hot === 1" src="/static/svg/game-icon-hot.svg" width="25" height="25" class="hot-icon"></v-img>
                  </v-img>
                  <v-card-title>
                    {{ truncatedRewardItemName(product.reward_item_name) }}
                  </v-card-title>
                  <!--                <v-card-title>{{ product.reward_item_name }}</v-card-title>-->
                  <v-card-subtitle class="subtitle_class">
                    <img src="static/image/rewardStore/coinIcon.png" alt="Coin Icon" class="coin-icon" />
                    <span class="px-1 points_required">{{ product.points_required }}</span>
                  </v-card-subtitle>

                  <v-card-actions class="justify-center action_class">
                    <v-btn v-if="product.is_active === 1 && product.total_quantity > 0" color="var(--v-newMobilePrimary-base)" class="claimNow" @click="handleClaimClick(product)">
                      {{ $t(`label.claimNow`) }}
                    </v-btn>
                    <v-btn class="claimNow" v-else-if="product.is_active === 1 && product.total_quantity <= 0" style="text-transform: none;" disabled>
                      {{ $t(`label.outOfStock`) }}
                    </v-btn>
                    <v-btn class="claimNow" v-else-if="product.total_quantity <= 0" style="text-transform: none;" disabled>
                      {{ $t(`label.outOfStock`) }}
                    </v-btn>
                    <v-btn v-else class="claimNow" style="text-transform: none;" disabled>{{ $t(`label.comingSoon`) }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <div class="text-center mb-6" v-if="rewardPagination.last_page > 1">
        <v-pagination
          class="game_pagination"
          v-model="rewardPagination.current_page"
          :length="rewardPagination.last_page"
          @input="changePage"
          :next-icon="'mdi-chevron-right'"
          :prev-icon="'mdi-chevron-left'"
          :total-visible="rewardPagination.last_page"
        ></v-pagination>
      </div>
    </div>
    <!-- mobile view    -->
    <v-row class="hidden-md-and-up justify-center">
      <div style="max-width: 100%">
        <div class="detailContainer_mobile">
          <v-container class="Product-Container">
            <v-row style="max-width: 100%; background-color: #EFF0F5; margin: 0px;">
              <v-col v-for="product in reward" style="padding: 6px;" :key="product.id" cols="6">
                <v-card :class="{ 'inactive-product': product.is_active !== 1 }" class="product-container">
                  <v-img :src="product.image_cdn" height="200" contain>
                    <v-img v-if="product.is_hot === 1" src="/static/svg/game-icon-hot.svg" width="25" height="25" class="hot-icon"></v-img>
                  </v-img>
                  <v-card-title>
                    <span class="px-1 points_required">{{ product.points_required }}</span>
                  </v-card-title>
                  <!--                <v-card-title>{{ product.reward_item_name }}</v-card-title>-->
                  <v-card-subtitle class="subtitle_class">
                    <span class="px-1">{{ truncatedRewardItemName(product.reward_item_name) }}</span>
                  </v-card-subtitle>

                  <v-card-actions class="justify-center action_class">
                    <v-btn v-if="product.is_active === 1 && product.total_quantity > 0" color="var(--v-newMobilePrimary-base)" class="claimNow" @click="handleClaimClick(product)">
                      {{ $t(`label.claimNow`) }}
                    </v-btn>
                    <v-btn class="claimNow" v-else-if="product.is_active === 1 && product.total_quantity <= 0" style="text-transform: none;" disabled>
                      {{ $t(`label.outOfStock`) }}
                    </v-btn>
                    <v-btn v-else class="claimNow" style="text-transform: none;" disabled>{{ $t(`label.comingSoon`) }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <div class="text-center mb-6" v-if="rewardPagination.last_page > 1">
        <v-pagination
          class="game_pagination"
          v-model="rewardPagination.current_page"
          :length="rewardPagination.last_page"
          @input="changePage"
          :next-icon="'mdi-chevron-right'"
          :prev-icon="'mdi-chevron-left'"
          :total-visible="rewardPagination.last_page"
        ></v-pagination>
      </div>
    </v-row>
  </div>
</template>

<script>
import stringFormat from 'string-format'
import { ROUTE_NAME } from '@/constants/route.constants'
import { uiHelper, locale, errorCodeHelper, formValidator } from '@/util'
import { SHARED } from '@/constants/constants'
import deleteIcon from '@/assets/svg/delete-icon.svg'
import _ from 'lodash'
import { REFERRAL_GET_OVERVIEW, REFERRAL_GET_DOWNLINES, REFERRAL_GET_REPORT, REFERRAL_GET_CONFIG } from '@/store/referral.module'
import {
  MEMBER_CLAIM_REWARD,
  MEMBER_RESET_CLAIM_REWARD_ITEM,
  MEMBER_RESET_VERIFY_POINTS,
  MEMBER_VERIFY_POINTS,
  REWARD_GET_ITEM
} from '@/store/reward.module'
import { MEMBER_DETAIL } from '@/store/member.module'

export default {
  name: 'rewardStoreItem',
  components: {
    deleteIcon
  },
  data: () => ({
    errorMsg: '',
    quickClaim: 0,
    step: 0,
    selectedVariation: null,
    selectedCurrency: 'BDT',
    selectedCategory: '',
    categories: [
      { label: locale.getMessage(`label.rw_all`), value: '' },
      { label: locale.getMessage(`label.rw_topredeem`), value: '1' },
      { label: locale.getMessage(`label.rw_electronics`), value: '2' },
      { label: locale.getMessage(`label.rw_homeliving`), value: '3' },
      { label: locale.getMessage(`label.rw_accessories`), value: '4' },
      { label: locale.getMessage(`label.rw_dataplan`), value: '5' }
    ],
    currentCurrency: uiHelper.getCurrency(),
    currentLanguage: uiHelper.getLanguage(),
    mobileNumberPrefixList: [
      {
        text: '+880',
        value: '880',
        currency: 'BDT',
        lang: 'bd'
      },
      {
        text: '+91',
        value: '91',
        currency: 'INR',
        lang: 'hi'
      },
      {
        text: '+977',
        value: '977',
        currency: 'NPR',
        lang: 'ne'
      }
    ],
    selectedOption: null,
    selectedProductImage: '',
    selectedProductIsHot: '',
    selectedProductName: '',
    productSelfCategory: '',
    coinRequired: '',
    creditGiven: '',
    turnoverMultiplier: '',
    gotVariations: [],
    redeemProductObj: {
      rewardId: '',
      rewardVariation: '',
      name: '',
      mobile: '',
      email: '',
      city: '',
      postcode: '',
      address1: '',
      address2: '',
      currency: SHARED.DEFAULT_CURRENCY,
      dialCode: uiHelper.getDialCode(),
      language: SHARED.DEFAULT_LANGUAGE
    },
    phoneNumberList: [{ selectedItem: null, items: [] }],
    alertShow: false,
    alertSuccess: false,
    alertMessage: '',
    claimNowConfirmation: false,
    requestSubmittedDialog: false,
    showNotEnoughBalanceDialog: false,
    stringFormat: stringFormat,
    language: uiHelper.getLanguage(),
    validator: formValidator,
    shared: SHARED,
    currentMobileSlide: 0,
    routeName: ROUTE_NAME
  }),
  async created() {
    this.metaTag()
    this.getMemberDetail()
    this.displayAllItems()
  },
  computed: {
    reward() {
      return this.$store.state.reward.rewardItem.data
    },
    rewardPagination() {
      return this.$store.state.reward.rewardItem.pagination
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    }
  },
  watch: {
    memberInfo() {
      let p = []
      let d = this.$store.state.member.info
      if (d != null) {
        if (d.mobileList != null && d.mobileList.length > 0) {
          d.mobileList.forEach(data => {
            if (data.is_verified) {
              p.push({
                value: data.value,
                is_verified: data.is_verified,
                is_primary: data.is_primary
              })
            }
          })
    //       this.phoneNumberList.items = [...p]
    //     }
    //   }
    //   return d
    // }
          this.phoneNumberList.items = [...p]
          let primaryPhone = p.find(phone => phone.is_primary === 1)
          if (primaryPhone) {
            this.phoneNumberList.selectedItem = primaryPhone
          }
        }
        return d
      }
    }
  },
  methods: {
    updateCurrencyIcon(dialCode) {
      const selectedPrefix = this.mobileNumberPrefixList.find(prefix => prefix.value === dialCode)
      if (selectedPrefix) {
        this.selectedCurrency = selectedPrefix.currency
      }
    },
    selectCategory(category) {
      this.selectedCategory = category
      let categoryId = category
      let filter = {
        currency: uiHelper.getCurrency(),
        size: 10,
        page: 1
      }
      this.$store.dispatch(`${REWARD_GET_ITEM}`, { filter, categoryId })
    },
    hideDocumentScrolling() {
      document.body.style.overflow = 'hidden'
    },
    showDocumentScrolling() {
      document.body.style.overflow = 'auto'
    },
    selectOption(option) {
      this.selectedVariation = option.variation_objects
      this.selectedOption = option
      // Handle the selection logic here
    },
    nextPage() {
      this.currentPage++
    },
    previousPage() {
      this.currentPage--
    },
    openCoinTipsDialog() {
      this.$parent.coinTipsDialog = true
    },
    closeclaimNowConfirmation() {
      this.resetRedeemProductForm()
      this.claimNowConfirmation = false
      this.showDocumentScrolling()
    },
    closerequestSubmittedDialog() {
      this.requestSubmittedDialog = false
      this.showDocumentScrolling()
    },
    closeNotEnoughBalanceDialog() {
      this.showNotEnoughBalanceDialog = false
      this.showDocumentScrolling()
    },
    resetRedeemProductForm() {
      // Save the current dialCode value
      const currentDialCode = this.redeemProductObj.dialCode
      // Reset the form properties
      this.redeemProductObj = {
        rewardId: '',
        rewardVariation: '',
        name: '',
        mobile: '',
        email: '',
        city: '',
        postcode: '',
        address1: '',
        address2: '',
        currency: SHARED.DEFAULT_CURRENCY,
        dialCode: uiHelper.getDialCode(),
        language: SHARED.DEFAULT_LANGUAGE
      }
      this.selectedVariation = null
      this.selectedOption = null
      this.errorMsg = ''
      // Manually reset the v-select component
      this.$refs.redeemProduct_form.resetValidation()
      this.$nextTick(() => {
        this.redeemProductObj.dialCode = currentDialCode
      })
    },
    nextPageButton() {
      if (this.$refs.redeemProduct_form_1.validate() && this.selectedVariation != null && this.selectedVariation != '') {
        this.step++
      } else if (this.selectedVariation === null || this.selectedVariation === '') {
        this.errorMsg = locale.getMessage('fieldErrorMessage.selectVariation')
        // this.openAlert(false, locale.getMessage('fieldErrorMessage.selectVariation'))
      }
    },
    previousPageButton() {
      this.step--
    },
    async redeemNows() {
      console.log('redeemProductObj.mobile before dispatch:', this.redeemProductObj.mobile);
      if (this.quickClaim === 1) {
        if (this.selectedVariation === null || this.selectedVariation === '') {
          this.errorMsg = locale.getMessage('fieldErrorMessage.selectVariation')
          // this.openAlert(false, locale.getMessage('fieldErrorMessage.selectVariation'))
        } else {
          this.redeemProductObj.rewardId = this.selectedItemId
          this.redeemProductObj.rewardVariation = this.selectedVariation
          if (this.phoneNumberList.selectedItem && this.phoneNumberList.selectedItem.value) {
            this.redeemProductObj.mobile = this.phoneNumberList.selectedItem.value
          } else {
            this.redeemProductObj.mobile = this.phoneNumberList.selectedItem
          }

          let filter = this.redeemProductObj
          try {
            // Await the completion of the asynchronous action
            await this.$store.dispatch(`${MEMBER_CLAIM_REWARD}`, { filter })

            // The action has completed successfully, check the response
            let response = this.$store.state.reward.redeemProduct
            if (response.complete && response.code !== 0) {
              this.openAlert(response.success, response.message)
            } else {
              this.claimNowConfirmation = false
              this.resetRedeemProductForm()
              this.requestSubmittedDialog = true
              this.hideDocumentScrolling()
              this.getMemberDetail()
            }
          } catch (error) {
            console.error('Error while redeeming reward:', error)
          }
        }
      } else {
        if (this.selectedVariation === null || this.selectedVariation === '') {
          this.errorMsg = locale.getMessage('fieldErrorMessage.selectVariation')
          // this.openAlert(false, locale.getMessage('fieldErrorMessage.selectVariation'))
        } else if (this.$refs.redeemProduct_form.validate() && this.selectedVariation != null) {
          this.redeemProductObj.rewardId = this.selectedItemId
          this.redeemProductObj.rewardVariation = this.selectedVariation
          if (this.phoneNumberList.selectedItem && this.phoneNumberList.selectedItem.value) {
            this.redeemProductObj.mobile = this.phoneNumberList.selectedItem.value
          } else {
            this.redeemProductObj.mobile = this.phoneNumberList.selectedItem
          }
          let filter = this.redeemProductObj
          try {
            // Await the completion of the asynchronous action
            await this.$store.dispatch(`${MEMBER_CLAIM_REWARD}`, { filter })

            // The action has completed successfully, check the response
            let response = this.$store.state.reward.redeemProduct
            if (response.complete && response.code !== 0) {
              this.openAlert(response.success, response.message)
            } else {
              this.claimNowConfirmation = false
              this.resetRedeemProductForm()
              this.requestSubmittedDialog = true
              this.hideDocumentScrolling()
              this.getMemberDetail()
            }
          } catch (error) {
            console.error('Error while redeeming reward:', error)
          }
        }
      }

      await this.$store.dispatch(`${MEMBER_RESET_CLAIM_REWARD_ITEM}`)
    },
    async getMemberDetail() {
      if (this.isLoggedIn) this.$store.dispatch(`${MEMBER_DETAIL}`)
    },
    async checkingMemberPoint() {
      this.$store.state.reward.verifyPoints.success = false
      let filter = {
        reward_id: this.selectedItemId
      }

      try {
        await this.getMemberDetail()
        await this.$store.dispatch(`${MEMBER_VERIFY_POINTS}`, { filter })
      } catch (error) {
        console.error('Error while verifying points:', error)
        // Handle the error as needed, e.g., show an error message
      }
    },
    async handleClaimClick(product) {
      if (!this.isLoggedIn) {
        this.goToLoginPage()
      } else {
        try {
          const selectedProduct = this.reward.find(item => item.id === product.id)
          this.selectedItemId = selectedProduct.id

          await this.checkingMemberPoint()

          let response = this.$store.state.reward.verifyPoints

          if (response.success === false) {
            console.log('Error Code = ' + response.code + ' Insufficient Coins')
            this.openAlert(response.success, errorCodeHelper.getErrorCodeDesc(response.code))
            await this.$store.dispatch(`${MEMBER_RESET_VERIFY_POINTS}`)
            console.log('applied reset verify points')

            // Handle success case with insufficient coins, or remove this line if not needed
          } else {
            console.log('Code = ' + response.code + ' Verified sufficient Coins')
            this.claimNow(product.id)
            // Perform any additional actions or handle the failure case
          }
        } catch (error) {
          console.error('Error while handling claim click:', error)
        }
      }
    },
    claimNow(productId) {
      // Handle claim action...
      if (!this.isLoggedIn) {
        this.goToLoginPage()
      } else {
        // if (this.memberInfo.rewardBalance <= ) need reward id,
        const selectedProduct = this.reward.find(product => product.id === productId)
        this.selectedItemId = selectedProduct.id
        this.creditGiven = selectedProduct.credit_given
        this.turnoverMultiplier = selectedProduct.turnover_multiplier
        this.selectedProductName = selectedProduct.reward_item_name
        this.productSelfCategory = selectedProduct.reward_category
        this.selectedProductImage = selectedProduct.image_cdn
        this.selectedProductIsHot = selectedProduct.is_hot
        this.coinRequired = selectedProduct.points_required
        this.quickClaim = selectedProduct.quick_claim
        this.gotVariations = selectedProduct.variations
        if (this.gotVariations.length === 1) {
          this.selectOption(this.gotVariations[0])
        }
        this.showsextraOptions = selectedProduct.extraOptions
        this.redeemProductObj.dialCode = uiHelper.getDialCode()
        this.step = 1
        this.claimNowConfirmation = true
        this.hideDocumentScrolling()
      }
    },
    changePage(targetPage) {
      this.selectedPage = targetPage
      this.selectCategoryByPage(this.selectedCategory)
      console.log(targetPage)
    },
    selectCategoryByPage(category) {
      this.selectedCategory = category
      let categoryId = category
      let filter = {
        currency: uiHelper.getCurrency(),
        size: 10,
        page: this.selectedPage
      }
      this.$store.dispatch(`${REWARD_GET_ITEM}`, { filter, categoryId })
    },
    openAlert(success, msg) {
      this.alertSuccess = success
      this.alertMessage = msg
      this.alertShow = true
    },
    getCurrentDateTime(countryCode) {
      const currentDate = new Date()

      let timeZoneOffsetMinutes = 0

      // Determine the time zone offset based on the countryCode
      switch (countryCode) {
        case 'INR':
          timeZoneOffsetMinutes = 5 * 60 + 30 // 5 hours and 30 minutes ahead of UTC
          break
        case 'NPR':
          timeZoneOffsetMinutes = 5 * 60 + 45 // 5 hours and 45 minutes ahead of UTC
          break
        case 'BDT':
          timeZoneOffsetMinutes = 6 * 60 // 6 hours ahead of UTC
          break
        // Add more cases for other countries if needed
        default:
          // Default to UTC (no offset)
          break
      }

      // Calculate the UTC time in milliseconds
      const utcTime = currentDate.getTime() + currentDate.getTimezoneOffset() * 60000

      // Calculate the target time using the UTC time and the specific offset
      const targetTime = new Date(utcTime + timeZoneOffsetMinutes * 60000)

      // Format options for date and time
      const dateOptions = { weekday: 'long', month: 'numeric', day: 'numeric', year: 'numeric' }
      const timeOptions = { hour: '2-digit', minute: '2-digit' }

      // Format the date and time according to the target time zone
      const formattedDate = targetTime.toLocaleDateString('en-US', dateOptions)
      const formattedTime = targetTime.toLocaleTimeString('en-US', timeOptions)

      // Construct the final formatted date and time string
      const formattedDateTime = `${formattedDate}, ${formattedTime}`

      return formattedDateTime
    },
    formatPoints(points) {
      return points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    truncatedRewardItemName(itemName) {
      const words = itemName
        .split(' ')
        .slice(0, this.wordLimit)
        .join(' ')
      if (itemName.split(' ').length > this.wordLimit) {
        return words + '...'
      } else {
        return words
      }
    },
    displayAllItems() {
      this.selectedCategory = ''
      let categoryId = this.selectedCategory
      let filter = {
        currency: uiHelper.getCurrency(),
        size: 10,
        page: this.selectedPage
      }
      this.$store.dispatch(`${REWARD_GET_ITEM}`, { filter, categoryId })
    },
    metaTag() {
      const currentUrl = window.location.href
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD|en-IN|hi-IN|en-NP|ne-NP)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      const Linksenin = `https://${domain}/en-IN${cleanPath}`
      const Linksenhi = `https://${domain}/hi-IN${cleanPath}`
      const Linksenennp = `https://${domain}/en-NP${cleanPath}`
      const Linksennenp = `https://${domain}/ne-NP${cleanPath}`

      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
      uiHelper.setHreflangLinksenin(Linksenin)
      uiHelper.setHreflangLinkshi(Linksenhi)
      uiHelper.setHreflangLinksennp(Linksenennp)
      uiHelper.setHreflangLinksnenp(Linksennenp)
    },
    goToRegisterPage() {
      this.$router.push({
        name: ROUTE_NAME.REGISTER
      })
    },
    goToLoginPage() {
      this.$router.push({
        name: ROUTE_NAME.LOGIN
      })
    },
    openMsgDialog(selectedMsg) {
      this.msgDialog = {
        show: true,
        msg: selectedMsg.message,
        title: selectedMsg.subject
      }
    },
    closeMsgDialog() {
      this.msgDialog = {
        show: false
      }
    }
  }
}
</script>

<style lang="scss">
.please-select-option {
  border: 1px solid red !important;
  color: var(--v-primary-base) !important;
  border-radius: 3px;
}
.action_class {
  @media (max-width: 959px) {
    position: relative !important;
    width: 100%;
  }
}

.heightIMG {
  height: 250px;
}
.item-button-close {
  background: var(--v-newMobilePrimary-base) !important;
  color: var(--v-primary-base) !important;
  border-radius: 3px;
}
.redeem-Now {
  background: var(--v-newMobilePrimary-base) !important;
  color: var(--v-primary-base) !important;
  border-radius: 3px;
}
.productName {
  font-size: 12px;
  height: 44px;
  align-items: center;
  display: flex;
}
.redeemInfo_field_pn {
  .v-input__control .v-input__slot {
    min-height: 30px !important;
    height: 30px !important;
    font-size: 12px;
  }
}
.phoneNumberPrefix {
  .v-input__control .v-input__slot {
    padding: 0 8px !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
    .v-input__prepend-inner {
      margin: 0px !important;
    }
  }
  .v-input__append-inner {
    margin: 0px !important;
  }
}
.selectOptions {
  border-radius: 3px;
  text-transform: none !important;
  font-size: 12px !important;
}
.selected-option {
  background: var(--v-newMobilePrimary-base) !important;
  color: var(--v-primary-base) !important;
  border-radius: 3px;
}
.custom-select {
  max-width: 200px;
  border-radius: 50px;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  padding: 0 10px;
}

.custom-select .v-input__control {
  border-radius: 50px;
  justify-content: center;
  align-items: center;
}
.custom-select > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:after {
  content: none !important;
  border-bottom: none !important;
}
.custom-select .v-input__slot {
  border-radius: 50px;
}

.custom-select .v-select__selections {
  border-radius: 50px;
}

.custom-select .v-input__control:before,
.custom-select .v-input__control:after {
  border-bottom: none;
}

.custom-select .v-input__control:before,
.custom-select .v-input__control:after {
  border-bottom: none;
}

.custom-select .v-select__selections {
  display: flex;
  align-items: center;
}

.custom-select .v-input__control {
  height: 35px;
}
.submitted_card {
  background-color: #fff;
  padding: 0px;
  .submitted_card_title {
    background: var(--v-primary-base);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px 5px 0px 0px;
    position: sticky;
    top: 0;
  }
}
.confirmation_card {
  background-color: #fff;
  padding: 0px;
  //height: 800px;
  .confirmation_card-title {
    background: var(--v-primary-base);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px 5px 0px 0px;
    position: sticky;
    top: 0;
  }
  .dialog-close-icon {
    color: #fff !important;
  }
}
.confirmation_card_wrapper {
  max-height: 80vh;
  overflow-y: auto;
}
.product-container {
  height: 100%;
  text-align: center;
  border: none;

  @media (max-width: 959px) {
    display: flex !important;
    flex-wrap: wrap;
    align-items: stretch;
    .subtitle_class {
      text-align: left;
    }
  }

  .v-card__title {
    height: 35px;
    word-break: break-word;
    text-align: left;
    padding: 6px 8px !important;
    font-size: 14px;
    line-height: normal;
    align-items: center;
    justify-content: start;
  }
  .v-card__subtitle {
    padding: 0 8px !important;
    margin-top: 0px !important;
    justify-content: start;
    align-items: center;
    display: flex;
  }
  .claimNow {
    color: #000 !important;
    width: 100%;
    height: 30px !important;
    font-size: 14px !important;
    text-transform: none;
  }
  .coin-icon {
    width: 20px;
    height: auto;
  }
}
.points_required {
  color: #0c8ce9;
  font-size: 18px;
}
.newReferralBanner-desktop {
  max-height: 160px;
  border-radius: 10px;

  .banner-desc {
    display: flex;
    justify-content: end;
    height: 100%;
    padding-bottom: 12px;
    padding-left: 12px;
    flex-direction: column;
    gap: 8px;
  }
}

.referralCode-card {
  border-radius: 15px !important;
  border: 1px dashed #1d1d1d !important;
}

.share-card {
  background-color: #0066d1 !important;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .theme--light.v-card {
    color: #ffffff;
  }

  border-radius: 15px !important;
}

.game-menu-image {
  background-color: #ffffff;
  -webkit-mask-image: var(--src);
  mask-image: var(--src);
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  width: 25px;
  height: 25px;
  margin-left: 8px;
}

.timeline-container {
  margin-right: 20px;
}

.v-timeline:before {
  bottom: 32px !important;
  height: 75% !important;
  top: 33px !important;
}

.tier-card.active-tier.v-card {
  background-color: #333333;
  color: var(--v-newMobilePrimary-base) !important;

  .v-card__subtitle,
  .v-card__text {
    color: var(--v-newMobilePrimary-base) !important;
  }
}

.active-tier {
  .v-timeline-item__inner-dot {
    background-color: #000000 !important;
    color: var(--v-newMobilePrimary-base) !important;
  }
}

.tier-card {
  background-color: #d9d9d9;
  color: #000000;
}

.beforeLoginBtn {
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.inpage-before-login-first {
  color: black;
  background-color: var(--v-newMobilePrimary-base);
  border: 1px solid #ddd;
  border-radius: 15px;
}

.inpage-navigator-before-login-second {
  color: white;
  background-color: var(--v-newMobileSecondary-base);
  border-radius: 15px;
}

.referral-tier {
  .title {
    font-size: 18px !important;
  }

  .detail-card {
    min-height: 240px;

    .detail {
      font: 16px;
    }
  }

  .mobile-carousel {
    height: 350px !important;
  }
}

@media (max-width: 959px) {
  .referral-tier {
    .v-timeline-item__dot {
      width: 35px;
    }
  }
}
</style>
